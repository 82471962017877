
.modal-background {
  display: none;
}

.modal-card-head {
  h1 {
    position: relative;
    width: 90%;
    overflow: hidden;

    &:after {
      content: "";
      display: inline-block;
      height: 0.5em;
      vertical-align: bottom;
      width: 100%;
      margin-right: -100%;
      margin-left: 10px;
      border-top: 1px solid #ccc;
    }
  }
}

.modal-card-body {
  white-space: pre-line;
}

.close-modal-button {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;

  .bg {
    background-color: #EC6A5E;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .icon {
      .mdi {
        color: #000000;
        font-size: 10px;
      }
    }
  }
}

.wikipedia-component {
  background: rgba(0, 0, 0, 0.05);
  padding: 10px 20px;
  border-radius: 10px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
