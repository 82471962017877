
#chat-parent.full-height {
  position: relative;
  min-height: calc(100svh - 70px);
  margin: 0 5%;
}

#chat {
  overflow: hidden;
  padding-right: 20px;
  padding-bottom: 30px;
  overflow-y: auto;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column-reverse;

  &.limitheight {
    max-height: calc(100svh - 160px);
  }
}

#connection-status {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  padding: 5px 20px;
  border-radius: 30px;
  background-color: #fcdc34;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

#answer-input {
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: 1024px) {
    padding: 0;
    bottom: 5px;
  }
}
