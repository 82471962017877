
.profile-picture-column {
  display: flex;
  align-items: end;

  img {
    width: 60px;
  }
}

.user {
  .profile-picture-column {
    justify-content: flex-end;
  }

  .box {
    background-color: #5483ed;
    color: #fff;
  }
}

.box {
  width: 75%;
  box-shadow: none;
  padding: 20px 30px;
  border-radius: 20px;
  position: relative;
  font-size: 1.3rem;
  line-height: 1.8;
  @media only screen and (max-width: 1300px) {
    width: 95%
  }

  @media only screen and (max-width: 769px) {
    padding: 10px 20px;
    font-size: 0.8rem;
    width: 90%;
    &::v-deep .is-size-5 {
      font-size: 1rem !important;
    }
  }
}

.profile-pic {
  max-height: 30px;
  max-width: 30px;
  @media only screen and (max-width: 769px) {
    max-height: 30px;
    max-width: 30px;
  }
}

::v-deep extrainfo {
  text-decoration: underline;
  text-decoration-style: dotted;
  &:hover {
    cursor: pointer;
  }
}
