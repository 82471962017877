
.user-answer {
  background: #fff;
  padding: 15px 18px 10px 18px;
  border-radius: 4px;
  position: relative;

  .progress-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%;
  }
}

.card-header {
  box-shadow: none;
}
