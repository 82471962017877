
.persona-option {
  position: relative;
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
  background: #f0f0f0;
  border: 2px solid transparent;
  word-wrap: break-word;
  @media only screen and (max-width: 1300px) {
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    ::v-deep .title {
      font-size: 0.9rem !important;
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 5px;
    margin: 2px;
    border-radius: 10px;
    ::v-deep .title {
      font-size: 0.7rem !important;
    }
  }
}

.selected-persona {
  border: 2px solid blue;
}

.lock-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  border-radius: 20px;
  @media only screen and (max-width: 900px) {
    border-radius: 10px;
  }
}
